


















































































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

import '../scss/DocumentsGroup.scss';
import { ProjectDocumentsActions, ProjectDocumentsMutations } from '@store/modules/project-documents/Types';
import { IProject, ISearchResult } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';
import { ProjectActions } from '@store/modules/project/Types';
import { makeBlur } from '@utility/makeBlur';
import { ITask } from '@store/modules/project-tasks/interfaces/Interfaces';
import { IDocument } from "@store/modules/project-documents/Interfaces";

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'DocumentsGroup',
    components: {
        SlideUpDown: () => import('vue-slide-up-down'),
        DocumentRow: () => import('@pages/the-documents/components/DocumentRow.vue'),
        BaseActions: () => import('@components/BaseActions/BaseActions.vue'),
    }
})

export default class DocumentsGroup extends Vue {
    @Prop() title!: string;
    @Prop() documents!: IDocument[];
    @Prop({ default: false }) isUnread!: boolean;
    @Prop({ default: false }) documentsShowDefault!: boolean;
    @Prop({ default: false }) usingInExecutiveDocs!: boolean;
    @Prop({ default: false }) showCircleNotification!: boolean;
    @Prop({ default: false }) agreementProcess!: boolean;
    @Prop({ default: true }) draggable!: boolean;

    @NSDocuments.Getter('filters') filters!: any[];
    @NSDocuments.Getter('viewMode') viewMode!: string;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSDocuments.Getter('filtersApply') filtersApply!: boolean;
    @NSDocuments.Getter('compositeShow') compositeShow!: boolean;
    @NSDocuments.Getter('unreadDocuments') documentsUnread!: string[];
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSProject.Getter('searchResult') searchResult!: ISearchResult;
    @NSDocuments.Getter('selectedDocuments') selectedDocuments!: string[];
    @NSProject.Action(ProjectActions.A_SET_SEARCH_RESULT) setSearchResult!: (any) => Promise<void>;
    @NSProject.Action(ProjectActions.A_SET_SEARCHING_PROCESS) setSearchingProcess!: (any) => Promise<void>;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_SELECTED_DOCUMENTS)
    setSelectedDocuments!: (payload: string[]) => void;

    @NSDocuments.Action(ProjectDocumentsActions.A_SEND_READ_DOCUMENTS)
    readDocuments!: (payload: { appId: string | number; ids: string[]; }) => Promise<void>;

    @NSDocuments.Action(ProjectDocumentsActions.A_SET_DRAGGING_STATE)
    setDraggingState!: (payload: boolean) => Promise<void>;

    selectedAllInGroup: boolean = false;
    documentsShow: boolean = false;
    spoilerDuration: number = 300;
    timeForFullAnimationColored: number = 6000;
    sortBy: string = 'author';
    sortTypeIsAsc: boolean = true;

    @Emit('show-unfinished-tasks')
    emitShowUnfinishedTasks(tasks: ITask[]): ITask[] {
        return tasks;
    }

    @Watch('selectedDocuments')
    onChangeSelectedDocuments() {
        let allIncludes = true;
        for (const doc of this.documents) {
            if (!this.selectedDocuments.includes(doc.uuid)) {
                allIncludes = false;
                break;
            }
        }
        this.selectedAllInGroup = allIncludes;
    }

    @Watch('documentsShow')
    onChangeDocumentsShow() {
        if (this.checkSearchResult()) {
            this.onChangeSearchResult();
        }
    }

    @Watch('searchResult')
    onChangeSearch() {
        if (this.checkSearchResult()) {
            this.onChangeSearchResult();
        }
    }

    @Watch('filters')
    onFilters() {
        if (this.filtersApply) {
            this.documentsShow = true;
        }
    }

    @Watch('filtersApply')
    onFiltersApply() {
        if (this.filtersApply) {
            this.documentsShow = true;
        }
    }

    mounted() {
        this.documentsShow = this.documentsShowDefault;
        if (this.checkSearchResult()) {
            this.documentsShow = true;
        }
        if (this.filtersApply) {
            this.documentsShow = true;
        }
    }

    get sortingDocuments() {
        return this.documents.sort((a, b) => {
            let keyA = a[this.sortBy];
            let keyB = b[this.sortBy];
            if (this.sortBy === 'author') {
                keyA = keyA.fullName;
                keyB = keyB.fullName;
            }
            if (keyA < keyB) return this.sortTypeIsAsc ? -1 : 1;
            if (keyA > keyB) return this.sortTypeIsAsc ? 1 : -1;
            return 0;
        });
    }

    get actionsItems() {
        let firstOptions = [
            this.actionObject('Выделить всё в категории', 'selectAllInCategory'),
        ];
        if (this.usingInExecutiveDocs && this.agreementProcess) {
            firstOptions.push(this.actionObject('Выполнить согласование', 'makeAgreement'));
        }
        if (this.viewMode === 'executiveDocs') {
            const generalPart = this.compositeShow
                ? [this.actionObject('Скачать комплект', 'downloadGroup'),]
                : [this.actionObject('Скачать комплект', 'downloadGroup'),
                    this.actionObject('Поставить задачу', 'createTask'),];
            if (this.selectedAllInGroup) {
                return [this.actionObject('Отменить выделение', 'unselectAllInCategory')].concat(generalPart)
            } else {
                return firstOptions.concat(generalPart);
            }
        }

        if (this.isSelectedUnreadDoc) {
            firstOptions = [
                this.actionObject('Отменить выделение', 'unselectAllInCategory'),
                this.actionObject('Прочитать выбранное', 'readSelected'),
            ];
        }
        if (this.isUnread) {
            firstOptions.push(
                this.actionObject('Прочитать все непрочитанные', 'readAllUnread'),
            );
        }
        return firstOptions.concat([
            this.actionObject('Закрепить категорию', 'pinCategory'),
        ]);
    }

    get isSelectedUnreadDoc(): boolean {
        return this.selectedDocuments.some((selectedDoc: string) => this.documentsUnread.includes(selectedDoc));
    }

    onDrag(e) {
        e.dataTransfer.setData('documentId', e.target.id);
        this.setDraggingState(true);
    }

    onDragEnd() {
        this.setDraggingState(false);
    }

    actionObject(label, action) {
        return {
            label: label,
            action: action,
        }
    }

    setSortBy(key) {
        this.sortBy = key;
        if (this.sortBy === key) {
            this.sortTypeIsAsc = this.sortBy === key ? !this.sortTypeIsAsc : true;
        }
        makeBlur();
    }

    onChangeSearchResult() {
        setTimeout(() => {
            let coloredLi = document.querySelector('.js--colored');
            if (coloredLi) {
                if (this.documents.filter(item => item.uuid === this.searchResult.item.id).length) {
                    this.documentsShow = true;
                }
                this.$nextTick(() => {
                    setTimeout(() => {
                        let coloredLis = document.querySelectorAll('.js--colored');
                        coloredLis.forEach(item => {
                            if (item.querySelector('.document-row__title')?.textContent?.trim() === this.searchResult.item.name.trim()) {
                                window.scrollTo(0, item.getBoundingClientRect().top - 300);
                            }
                        });
                        setTimeout(() => {
                            this.setSearchResult({});
                        }, this.timeForFullAnimationColored);
                        this.setSearchingProcess(false);
                    }, 1000);
                })
            }
        }, 1);
    }

    checkSearchResult() {
        if (this.searchResult && this.searchResult.group === 'Документы') {
            this.setSearchingProcess(false);
            return this.sortingDocuments.filter(doc => doc.uuid === this.searchResult.item.id).length;
        }
        return false;
    }

    checkDocSearchResult(item) {
        if (this.searchResult && this.searchResult.item && item.uuid === this.searchResult.item.id) {
            return true;
        }
    }

    spoilerToggle() {
        this.documentsShow = !this.documentsShow;
    }

    selectAllInGroup() {
        const idList: string[] = [];
        this.documents.forEach((item) => {
            if (this.selectedDocuments.indexOf(item.uuid) === -1) {
                idList.push(item.uuid);
            }
        });
        this.setSelectedDocuments(this.selectedDocuments.concat(idList));
        this.selectedAllInGroup = true;
    }

    unselectAllInGroup() {
        const idsForRequest: string[] = [];
        const idList: string[] = [];
        this.documents.forEach((item) => {
            idList.push(item.uuid);
        });
        this.selectedDocuments.forEach((id) => {
            if (idList.indexOf(id) === -1) {
                idsForRequest.push(id);
            }
        })
        this.setSelectedDocuments(idsForRequest);
        this.selectedAllInGroup = false;
    }

    readSelectedDocuments(docsIds) {
        this.readDocuments({
            appId: this.projectData.id,
            ids: docsIds,
        }).then(() => {
            this.$emit('reload-project');
        });
    }

    onSelectAction(e) {
        switch (e.action){
            case 'selectAllInCategory':
                this.selectAllInGroup();
                break;
            case 'unselectAllInCategory':
                this.unselectAllInGroup();
                break;
            case 'readSelected':
                this.readSelectedDocuments(this.selectedDocuments);
                break;
            case 'readAllUnread':
                this.readSelectedDocuments(this.documents.map(doc => doc.uuid));
                break;
            case 'downloadGroup':
                this.$emit('download-group');
                break;
            case 'makeAgreement':
                this.$emit('open-agreement', {title: this.title});
                break;
            default:
                return false;
        }
    }
};
